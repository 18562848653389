html, body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.middle-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
header {
  min-height: 50px;
  background-color: #460073;
}
.footer {
  background-color: #d2d2d2;
  color: #fff;
  
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.footer .footer-text {
  color: #000;
  font-size: 12px;
  text-align: center;
  padding: 0.4rem;
}
.accordion-button {
  font-size: 12px;
}

.textwrap {
  text-wrap: wrap;
  font-size: 10px;
  color: #460073;
  font-weight: bold;
  overflow-wrap: break-word;
  padding: 1px;
}

.col-left {
  overflow: auto;
  
  height: 100%;
  border-right: 1px solid var(--border-color);
}
.col-right {
  width: 75%;
  height: 100%;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top:1;
  margin-top: 60px;
}
.profileblock {
  background: #f2f2f2!important;
  text-align: center;
  padding: 15px!important;
}
.col-code {
  overflow: auto;
  height: calc(100vh - 70px)
}

#basic-nav-dropdown::before{
  display: none; 
}

.banner {
  width: 100%;
  height: calc(100vh - 70px)
}

.disable-all-controls {
  pointer-events: none;
  opacity: 0.8
}


.CodeMirror {
  font-family: Arial, monospace;
  font-size: 12px;
}

.react-flow__node-custom {
  font-size: 10px;
  width: 180px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
}
.drawflow {
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
  
  background: var(--background-color);
  background-size: 25px 25px;
  background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px), linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

.react-flow__node-custom .react-flow__handle {
  top: 24px;
  right: -15px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.react-flow__node-PresentationConfig {
  font-size: 10px;
  width: 60px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
}

.react-flow__node-PresentationConfig .react-flow__handle {
  /*top: 16px;*/
  right: -5px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.react-flow__node-Page {
  font-size: 10px;
  width: 60px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
}

.react-flow__node-Page .react-flow__handle {
  /*top: 10px;*/
  right: -5px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.tooltip .tooltip-inner {
  width: 70em;
  white-space: normal;
}
.react-flow__node-Question {
  font-size: 10px;
  width: 250px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
}

.react-flow__node-Question .react-flow__handle {
  /*top: 16px;*/
  right: -5px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.custom-node__header {
  padding: 8px 10px;
  border-bottom: 1px solid #e2e8f0;
}

.custom-node__body {
  padding: 10px;
}

.custom-node__select {
  position: relative;
  margin-bottom: 10px;
}

.custom-node__select select {
  width: 100%;
  margin-top: 5px;
  font-size: 10px;
}

.brms-underline {
  border-bottom: 5px solid #5a5a5a; /* Change the color code to your desired color */
  display: inline-block; /* Ensures that the underline is only as wide as the text */
  padding-bottom: 2px; /* Adjust the padding as needed */
  font-weight: 700;
  color: #5a5a5a;
  font-size: 15px
}

.brms-left {
  font-weight: 300;
  font-size: 12px;
  overflow: auto;
  border-right: 1px solid;
  height: calc(100vh - 70px);
}

.brms-header {
  text-align: center;
}


.custom-input-wrapper {
  position: relative;
}

.custom-input {
  border: 1px solid gray;
  border-radius: 3px;
  position: relative;
  width: 100%;
  margin: 0px;
  line-height: 6ex;
  padding: 0.25rem 0.5rem;
}

.custom-label {
  position: absolute;
  top: -1.2ex;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
}

.custom-checkbox-container {
  display: inline-block;
  border: 2px solid #007bff; /* Set your desired border color */
  padding: 5px;
  border-radius: 5px;
}

.custom-checkbox {
  margin-right: 5px;
}

.form-check-input {
  border-color: #000;
}