/* src/components/ProfileImage.css */

.profile-image-container {
    width: 40px; /* Adjust the size as needed */
    height: 40px; /* Adjust the size as needed */
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #fff; /* Optional: Add a border around the circle */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional: Add a shadow effect */
  }
  
.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ul-style {
    list-style: none; /* Remove default list styles */
        justify-content: space-around; /* Evenly distribute items along the main axis */
        
  }

  .a_class {
    color: #fff; /* Text color */
    text-decoration: none; /* Remove underline */
    padding: 10px; /* Add padding around the text */
    display: block; /* Make the entire link area clickable */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }

  /* Change background color on hover */
  .a_class:hover {
    background-color: #555;
  }

  


  