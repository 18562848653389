.brmsrulename {
  font-size: 10px;
  font-weight: bold;
}
.brmsruledesc {
  font-size: 10px;
  color: grey;
}



.font12 {
    font-size: 12px;
}

.rule-details {
    height: calc(100vh - 170px);
}

.bottom-element {
    position: absolute;
    bottom: 0;
    left: 90%;
    padding: 5px;
  }
.rule-tab-content {
    min-height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
    overflow-y: auto;
}
