:root {
    --border-color: #cacaca;
    --background-color: #ffffff;
  
    --background-box-title: #f7f7f7;
  }
  
  html, body {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
  }
  
  
  header h2 {
    margin: 0px;
    line-height: 66px;
  }
  header a {
    color: black;
  }
  .them-edit-link {
    position: absolute;
    top: 10px;
    right: 100px;
    color: black;
    font-size: 40px;
  }
  .them-edit-link a {
    text-decoration: none;
  }
  
  
  .wrapper {
    width: 100%;
    height: calc(100vh - 67px);
    display: flex;
  }
  

  
  .drag-drawflow {
    line-height: 50px;
    border-bottom: 1px solid var(--border-color);
    padding-left: 20px;
    cursor: move;
    user-select: none;
  }
  
  